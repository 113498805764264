import React, { useContext } from 'react'
import { Context } from './Context'
import { Provider } from './Provider'

/**
 *
 */
export const FILTER_TYPE = {
  RECENT_POSTS: 'recentPosts',
  RECOMMEND_POSTS: 'recommendPosts',
}

/**
 *
 */
export const Filter = ({ typeList = Object.values(FILTER_TYPE), children }) => {
  const data = useContext(Context)

  const products = typeList.reduce((pre, cur) => {
    return data[cur] ? pre.concat(data[cur]) : pre
  }, [])

  return <Provider value={{ products }}>{children}</Provider>
}
