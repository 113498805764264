import React from 'react'
import * as TopBanner from './TopBanner'
import * as Campaign from 'modules/campaign'
import * as Reference from 'modules/campaign/Reference'
import * as CampaignBanner from './CampaignBanner'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel.styles.scss'

export const SliderItems = () => {
  const { data } = React.useContext(Campaign.Context)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: false,
        },
      },
    ],
  }
  return (
    <Slider className="Banner" {...settings}>
      <TopBanner.Default />
      {data.campaignBanner.nodes.map((entry, i) => (
        <Reference.Provider value={entry} key={i}>
          <CampaignBanner.Default />
        </Reference.Provider>
      ))}
    </Slider>
  )
}
