import React from 'react'
import { TopLogo } from 'components/Logo'
import * as Layouts from './Layouts'
import * as Containers from './Containers'
import * as styles from './styles.module.scss'

export const Default = ({
  components: {
    Layout: {
      Wrapper = Layouts.Wrapper,
      Header = Layouts.Header,
      Body = Layouts.Body,
      Footer = Layouts.Footer,
    } = {},
    Slider = Containers.SliderItems,
  } = {},
}) => {
  return (
    <Wrapper>
      <Header>
        <TopLogo className={styles.Logo} />
      </Header>
      <Body>
        <Slider />
      </Body>
      <Footer></Footer>
    </Wrapper>
  )
}
