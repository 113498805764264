import React from 'react'
import * as styles from './styles.module.scss'

/**
 *
 */
export const Wrapper = ({ children }) => {
  return <div className={styles.Layout_Wrapper}>{children}</div>
}

/**
 *
 */
export const Header = ({ children }) => {
  return <div className={styles.Layout_Header}>{children}</div>
}

/**
 *
 */
export const Body = ({ children }) => {
  return <div className={styles.Layout_Body}>{children}</div>
}

/**
 *
 */
export const Footer = ({ children }) => {
  return <div className={styles.Layout_Footer}>{children}</div>
}
