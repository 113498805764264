//FIXME
import React, { useEffect } from 'react'
import { Entry as EntryContent } from './Entry'
import * as styles from './styles.module.scss'
import * as Products from 'modules/product/components'
import { Filter, FILTER_TYPE } from 'modules/product/components/Filter'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const Entry = () => {
  const isBrowser = typeof window !== 'undefined'
  const WIDTH_THRESHOLD = 767
  const getWidth = () => {
    if (isBrowser) {
      return window.innerWidth
    }
    return 0
  }
  const [width, setWidth] = React.useState(getWidth())
  const updateWidth = () => {
    if (isBrowser) {
      setWidth(window.innerWidth)
    }
  }

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener(`resize`, updateWidth, {
        capture: false,
        passive: true,
      })
      return () => window.removeEventListener(`resize`, updateWidth)
    }
  }, [width])
  if (width > WIDTH_THRESHOLD) {
    return (
      <Filter typeList={[FILTER_TYPE.RECOMMEND_POSTS]}>
        <Products.Each start_offset={0} end_offset={6} postType={1}>
          <EntryContent />
        </Products.Each>
      </Filter>
    )
  }
  return (
    <Filter typeList={[FILTER_TYPE.RECOMMEND_POSTS]}>
      <Products.Each start_offset={0} end_offset={10} postType={1}>
        <EntryContent />
      </Products.Each>
    </Filter>
  )
}

/**
 *
 */
export const Content = () => (
  <Container>
    <Entry />
  </Container>
)
