import React from 'react'
import * as Layouts from './Layouts'
import * as Containers from './Containers'

export const Default = ({
  components: {
    Layouts: {
      Wrapper = Layouts.Wrapper,
      Header = Layouts.Header,
      Body = Layouts.Body,
      Footer = Layouts.Footer,
    } = {},
  } = {},
}) => {
  return (
    <Wrapper>
      <Containers.ToCampaignListLink>
        <Header>
          <Containers.Hero />
        </Header>
        <Body>
          <Containers.Title />
          <Containers.Description />
        </Body>
      </Containers.ToCampaignListLink>
      <Footer>
        <Containers.ToCampaignOverviewLink />
      </Footer>
    </Wrapper>
  )
}
