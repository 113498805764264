import React from 'react'
import * as styles from './styles.module.scss'
import * as Product from 'modules/product/components/Reference'
import { ToProductPage } from 'components/shared/Links'

export const Entry = () => {
  return (
    <div className={styles.Entry_Item}>
      <ToProductPage className={styles.Entry_Item_Link}>
        <div className={styles.Entry_Item_Image}>
          <Product.Properties.Image />
        </div>
        <Product.Properties.SliceTitle className={styles.Entry_Item_Title} />
        <Product.Properties.Date className={styles.Entry_Item_Date} />
      </ToProductPage>
    </div>
  )
}
