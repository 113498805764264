import React from 'react'
import * as Literals from './Literals'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'
import { ToCategoriesPage } from 'components/shared/Links'
import { DefaultButton } from 'components/shared/Button'
import ButtonArrowImage from '/static/img/arrow-icon.svg'

export const Title = ({
  components: {
    H1Decorator = Styled.H1Title,
    H2Decorator = Styled.H2Title,
    H3Decorator = Styled.H3Title,
  } = {},
}) => {
  return (
    <>
      <H1Decorator>
        <Literals.H1Title />
      </H1Decorator>
      <H2Decorator>
        <Literals.H2Title />
      </H2Decorator>
      <H3Decorator>
        <Literals.H3Title />
      </H3Decorator>
    </>
  )
}

export const Detail = ({ components: { Decorator = Styled.Detail } = {} }) => {
  return (
    <Decorator>
      <Literals.DetailText />
    </Decorator>
  )
}

export const ButtonIcon = () => {
  return (
    <img
      className={styles.Containers_Button_Icon}
      src={ButtonArrowImage}
      alt="→"
    />
  )
}

export const ScrollIcon = () => {
  return <div className={styles.Scroll_Icon} />
}

export const ToCategoriesButton = () => {
  return (
    <ToCategoriesPage>
      <DefaultButton className={styles.Containers_Button}>
        <Literals.ToCategoriesButtonText
          className={styles.Containers_Button_Text}
        />
        <ButtonIcon />
      </DefaultButton>
    </ToCategoriesPage>
  )
}
