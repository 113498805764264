import React from 'react'
import * as Literals from './Literals'
import * as Styled from './Styled'
import * as PostsBase from './Posts'
import * as Campaign from 'modules/campaign'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from './styles.module.scss'
import { navigate } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { Pagination } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  ul: {
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#2fb2ce',
      display: 'flex',
    },
  },
  root: {
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `center`,
    alignItems: 'center',
    marginTop: '50px',
  },
}))

/**
 *
 */
export const Pager = () => {
  const classes = useStyles()
  const { pageContext } = React.useContext(Campaign.Context)
  const handleChange = (event, value) => {
    value === 1 ? navigate(`/campaign/`) : navigate(`/campaign/page/${value}`)
  }
  return (
    <div className={classes.root}>
      <Pagination
        classes={{ ul: classes.ul }}
        defaultPage={pageContext.humanPageNumber}
        count={pageContext.numberOfPages}
        onChange={handleChange}
      />
    </div>
  )
}

/**
 *
 */
export const Hero = () => {
  return (
    <StaticImage
      src="../../img/04_fvimage.png"
      className={styles.Containers_Hero}
    />
  )
}

/**
 *
 */
export const SectionTitle = ({
  components: {
    SectionTitle = Literals.SectionTitle,
    Decorator = Styled.SectionTitle,
  } = {},
} = {}) => {
  return (
    <Decorator>
      <SectionTitle />
    </Decorator>
  )
}

/**
 *
 */
export const Logo = () => {
  return <div className={styles.Logo} />
}

/**
 *
 */
export const Title = ({
  components: {
    Decorator = Styled.Title,
    CampaignTitle = Literals.CampaignTitle,
  } = {},
}) => {
  return (
    <Decorator>
      <CampaignTitle />
    </Decorator>
  )
}

/**
 *
 */
export const Posts = () => {
  return <PostsBase.Default />
}
