//FIXME
import React from 'react'
import { Content as HeaderContent } from './Header'
import { Content as BodyContent } from './Body'
import { Content as FooterContent } from './Footer'
import * as styles from './styles.module.scss'

/**
 *
 */
const Header = () => (
  <div className={styles.Header_Content}>
    <HeaderContent />
  </div>
)

/**
 *
 */
const Body = () => (
  <div>
    <BodyContent />
  </div>
)

/**
 *
 */
const Footer = () => (
  <div className={styles.Footer_Content}>
    <FooterContent />
  </div>
)

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Header />
    <Body />
    <Footer />
  </Container>
)
