import React from 'react'
import * as styles from './styles.module.scss'

/**
 *
 */
export const Title = ({ children }) => {
  return <h1 className={styles.Styled_Title}>{children}</h1>
}

/**
 *
 */
export const Description = ({ children }) => {
  return <p className={styles.Styled_Description}>{children}</p>
}

/**
 *
 */
export const Button = ({ children, ...props }) => {
  return (
    <button {...props} className={styles.Styled_Button}>
      {children}
    </button>
  )
}

/**
 *
 */
export const Link = ({ children, url }) => {
  return (
    <a href={url} className={styles.Styled_Link}>
      {children}
    </a>
  )
}
