import React from 'react'

/**
 *
 */
export const Detail = ({ children }) => {
  return <p>{children}</p>
}

export const Title = ({ children }) => {
  return <div>{children}</div>
}

/**
 *
 */
export const H1Title = ({ children, className }) => {
  return <h1 className={className}>{children}</h1>
}

/**
 *
 */
export const H2Title = ({ children, className }) => {
  return <h2 className={className}>{children}</h2>
}

/**
 *
 */
export const H3Title = ({ children, className }) => {
  return <h3 className={className}>{children}</h3>
}

/**
 *
 */
export const ButtonText = ({ children, className }) => {
  return <div className={className}>{children}</div>
}
