import React from 'react'

/**
 *
 */
export const Title = ({ title }) => <>{title}</>

/**
 *
 */
export const Link = () => {
  return <>記事を読む</>
}
