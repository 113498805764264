//FIXME
import React from 'react'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const Title = () => (
  <div className={styles.Title}>
    <h3 className={styles.Title_h3}>recommend</h3>
    <div className={styles.Title_Icon} />
  </div>
)

/**
 *
 */
const Text = () => (
  <div className={styles.Text}>
    <p>おすすめ商品</p>
  </div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Title />
    <Text />
  </Container>
)
