import React from 'react'

/**
 *
 */
export const DetailText = () => {
  return (
    <>
      私たちは“今までにない建材情報との出会いの場”を創出します。製品を生み出すまでの開発秘話、素材や機能のこだわり、製作者の思い。製品カタログでは語り尽くせないストーリーをお届けします。
    </>
  )
}

/**
 *
 */
export const ToCategoriesButtonText = () => {
  return <>カテゴリから探す</>
}

/**
 *
 */
export const H1Title = () => {
  return <>A story</>
}

/**
 *
 */
export const H2Title = () => {
  return <>not in the catalog.</>
}

/**
 *
 */
export const H3Title = () => {
  return <>カタログにはないストーリーを。</>
}
