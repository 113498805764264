import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import * as Literals from './Literals'
import * as Styled from './Styled'
import * as Campaign from 'modules/campaign/Reference'
import * as styles from './styles.module.scss'
import { HTMLRenderer } from 'components/shared/Excerpt/render'

export const Hero = () => {
  const data = React.useContext(Campaign.Context)
  const imageSrc = data.featured_media.localFile.childImageSharp.gatsbyImageData
  return <MyImage largeImage={imageSrc} smallImage={imageSrc} />
}

export function MyImage({ largeImage, smallImage }) {
  const images = withArtDirection(getImage(largeImage), [
    {
      media: '(max-width: 1024px)',
      image: getImage(smallImage),
    },
  ])

  return <GatsbyImage className={styles.ArtDirected} image={images} />
}

/**
 *
 */
export const Title = ({ components: { Decorator = Styled.Title } = {} }) => {
  const data = React.useContext(Campaign.Context)
  return (
    <Decorator>
      <Literals.Title title={data.title} />
    </Decorator>
  )
}

/**
 *
 */
export const Description = ({
  components: { Decorator = Styled.Description } = {},
}) => {
  const data = React.useContext(Campaign.Context)
  return (
    <Decorator>
      <HTMLRenderer value={data.excerpt} />
    </Decorator>
  )
}

/**
 *
 */
export const Button = ({
  components: { Decorator = Styled.Button } = {},
  children,
}) => {
  return <Decorator>{children}</Decorator>
}

/**
 *
 */
export const ToCampaignListLink = ({
  components: { Decorator = Styled.Link } = {},
  children,
}) => {
  const data = React.useContext(Campaign.Context)
  const categoryFilter = data.categories.filter((node) => {
    return node.slug !== 'campaign'
  })
  return (
    <Decorator url={`/campaign/${categoryFilter[0].slug}`}>
      {children}
    </Decorator>
  )
}

export const Test = ({ children }) => {
  return <div>{children}</div>
}

/**
 *
 */
export const ToCampaignOverviewLink = ({
  components: { Decorator = Styled.Link } = {},
}) => {
  const data = React.useContext(Campaign.Context)
  const categoryFilter = data.categories.filter((node) => {
    return node.slug !== 'campaign'
  })
  return (
    <Button>
      <Decorator
        url={`/campaign/${categoryFilter[0].slug}/${data.wordpress_id}`}
      >
        <Literals.Link />
      </Decorator>
    </Button>
  )
}
