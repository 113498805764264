//FIXME
import React from 'react'
import * as Links from 'components/shared/Links'
import { DefaultButton } from 'components/shared/Button'
import * as styles from './styles.module.scss'

/**
 *
 */
export const Content = () => (
  <div className={styles.Content}>
    <Links.ToCategoriesPage>
      <DefaultButton className={styles.Content_Link_Button} />
    </Links.ToCategoriesPage>
  </div>
)
