import React from 'react'
import * as Layouts from './Layouts'
import * as Containers from './Containers'

export const Default = ({
  components: {
    Layouts: Wrapper = Layouts.Wrapper,
    Header = Layouts.Header,
    Body = Layouts.Body,
    Footer = Layouts.Footer,
  } = {},
} = {}) => {
  return (
    <Wrapper>
      <Header></Header>
      <Body>
        <Containers.Title />
        <Containers.Detail />
      </Body>
      <Footer>
        <Containers.ToCategoriesButton />
      </Footer>
    </Wrapper>
  )
}
