// extracted by mini-css-extract-plugin
export var Container = "styles-module--Container--Vz+d3";
export var Links_Content = "styles-module--Links_Content--KenVL";
export var icon_Content = "styles-module--icon_Content--iV4bR";
export var tab1 = "styles-module--tab1--IA5yQ";
export var tab1_content = "styles-module--tab1_content--xHHrQ";
export var tab2 = "styles-module--tab2--4fw2T";
export var tab2_content = "styles-module--tab2_content--FZrO4";
export var tab3 = "styles-module--tab3---8IUw";
export var tab3_content = "styles-module--tab3_content--hdp-d";
export var tab_container = "styles-module--tab_container--jXPig";
export var tab_content = "styles-module--tab_content--5KCyU";
export var tab_content_description = "styles-module--tab_content_description--hdTSg";
export var tab_item = "styles-module--tab_item--b2SJZ";